import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import Lottie from 'lottie-react';
import animation from './animation-mobile.json';
import Form from "./Form";
import Result from "./Result";
import './style.css';

const LeaderboardMobile = memo(() => {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [resultValue1, setResultValue1] = useState("");
    const [resultValue2, setResultValue2] = useState("");
    const [resultValue3, setResultValue3] = useState("");
    const [resultValue4, setResultValue4] = useState("");
    // const [showLeaderboard, setShowLeaderboard] = useState(true);
    const [userData, setUserData] = useState([]);
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPageNumberGroup, setSelectedPageNumberGroup] = useState(0);
    const [indexOfLastItem, setIndexOfLastItem] = useState(0);
    const [indexOfFirstItem, setIndexOfFirstItem] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const animationData = animation;
    const formatter = new Intl.DateTimeFormat('en', {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
    });

    useEffect(() => {
        setLeaderboardData([]); // empty the leaderboardData state

        const fetchData = async () => {
            let response;
            const today = new Date();
            if (selectedDay.toDateString() === today.toDateString()) {
                response = await axios.get(
                    `https://sbapi.aplikacii.info/game-session-leaderboard`
                );
            } else {
                if (selectedDay.getDate() === 27 && selectedDay.getMonth() === 1 && selectedDay.getFullYear() === 2023) {
                    selectedDay.setHours(9);
                    selectedDay.setMinutes(0);
                    selectedDay.setSeconds(0);
                } else {
                    selectedDay.setHours(9);
                    selectedDay.setMinutes(35);
                    selectedDay.setSeconds(0);
                }
                response = await axios.get(
                    `https://sbapi.aplikacii.info/leaderboards?_limit=500&_sort=rankingPosition:ASC&created_at_gt=${selectedDay.toISOString()}&created_at_lt=${new Date(
                        selectedDay.getTime() + 25 * 60 * 60 * 1000
                    ).toISOString()}`
                );
            }

            setLeaderboardData(response.data);
            setTotalPages(Math.ceil(response.data.length / 100));
        };

        fetchData();
    }, [selectedDay]);

    useEffect(() => {
        const itemsPerPage = 100;
        setIndexOfLastItem(selectedPageNumberGroup * itemsPerPage);
        setIndexOfFirstItem(indexOfLastItem - itemsPerPage);
    }, [selectedPageNumberGroup]);

    const handleNextClick = () => {
        setSelectedDay(new Date(selectedDay.getTime() + 24 * 60 * 60 * 1000));
        setCurrentPage(1);
        setSelectedPageNumberGroup(0);
    };

    const handlePrevClick = () => {
        setSelectedDay(new Date(selectedDay.getTime() - 24 * 60 * 60 * 1000));
        setCurrentPage(1);
        setSelectedPageNumberGroup(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const itemsPerPage = 100;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentItems([]);
        setTimeout(() => {
            setCurrentItems(leaderboardData.slice(startIndex, endIndex));
        }, 0);
    }, [leaderboardData, currentPage]);

    // const itemsPerPage = 100;
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
    // // const currentItems = leaderboardData.slice(startIndex, endIndex);
    // setCurrentItems(leaderboardData.slice(startIndex, endIndex));

    const pageNumbers = Array(totalPages).fill().map((_, i) => i + 1);

    const pageNumbersGroup = [];
    for (let i = 1; i <= totalPages; i += 100) {
        pageNumbersGroup.push(`${i}-${Math.min(i + 99, totalPages)}`);
    }

    const handleSearchClick = () => {
        setShowForm(true);
    };

    const handleFormSubmit = (inputValue) => {
        axios
            .get(`https://sbapi.aplikacii.info/leaderboard-user-check/${inputValue}`)
            .then((response) => {
                const dateObj = new Date(response.data.userDatePlayed);
                const dateStr = dateObj.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}).split('/').join('.');
                setResultValue1(response.data.userPoints);
                setResultValue2(response.data.userRank);
                setResultValue3(dateStr);
                setResultValue4('');
                setShowForm(false);
                setShowResult(true);
                // setUserData([...userData, response.data]);
            })
            .catch((error) => {
                if (error.response.data.data === 20404) {
                    axios
                        .get(`https://sbapi.aplikacii.info/game-session-user-check/${inputValue}`)
                        .then((response) => {
                            const dateObj = new Date(response.data.userDatePlayed);
                            const dateStr = dateObj.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}).split('/').join('.');
                            setResultValue1(response.data.userPoints);
                            setResultValue2(response.data.userRank);
                            setResultValue3(dateStr);
                            setResultValue4('');
                            setShowForm(false);
                            setShowResult(true);
                            // setUserData([...userData, response.data]);
                        })
                        .catch((error) => {
                            console.log(error);
                            setResultValue4('Ovaj ID ne postoji. Posetite svoj korisnički nalog i proverite ponovo koji je Vaš ID broj.');
                            setShowForm(false);
                            setShowResult(true);
                        });
                } else {
                    console.log(error);
                }
            });
        setShowForm(false);
    };

    const handleBackClick = () => {
        setShowForm(false);
        setShowResult(false);
    };

    return (
        <div className="App mobile__view">
            <div className="background">
                <Lottie
                    className="lottie-anim"
                    animationData={animationData}
                    loop={false}
                    autoplay={true}
                />
            </div>
            <div className="content">
                {!showForm && !showResult && (<div className="leaderboard">
                    <div className="leaderboard__nav">
                        <button className="prev btn" onClick={handlePrevClick}>
                            {/* Prev */}
                        </button>
                        <div className="leaderboard__nav-date">
                            Bonus Voćke - tabela dobitnika za {selectedDay.toLocaleDateString(
                                "en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                }).replace(/\//g, '.')}
                        </div>
                        <button className="next btn" onClick={handleNextClick}>
                            {/* Next */}
                        </button>
                    </div>
                    <div className="leaderboard__wrapper">
                        <table className="leaderboard__table">
                            <tbody>
                                {currentItems.map((item, index) => (
                                    <tr
                                        key={item.uid}
                                        className={
                                            item.uid === "logged-in-user-id"
                                                ? "leaderboard__row leaderboard__row--highlight"
                                                : "leaderboard__row"
                                        }
                                    >
                                        <td>{item.rankingPosition.toString().padStart(3, '0')}</td>
                                        <td>Korisnik {item.uid}</td>
                                        <td>{new Date(item.datePlayed).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}h</td>
                                        <td>{item.points} poena</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* You can simplify the pagination section by using a range of numbers and map over it to create the span elements. */}
                    <div className="leaderboard__pagination">
                        {pageNumbers.map((number) => {
                            return (
                                <span
                                    key={number}
                                    className={
                                        number === currentPage
                                            ? "leaderboard__pagination-item leaderboard__pagination-item--active"
                                            : "leaderboard__pagination-item"
                                    }
                                    onClick={() => handlePageChange(number)}
                                >
                                    {(number - 1) * 100 + 1}-{number * 100}
                                </span>
                            );
                        })}
                    </div>
                    <div className="button_wrapper">
                        <button onClick={handleSearchClick} className="mat-button green-button">PRETRAŽI</button>
                    </div>
                    {/* <button onClick={handleAddClick}>Add</button> */}
                </div>)}
                {showForm && (
                    <Form
                        onSubmit={handleFormSubmit}
                        onBackClick={handleBackClick}
                    />
                )}
                {showResult && (
                    <Result
                        value1={resultValue1}
                        value2={resultValue2}
                        value3={resultValue3}
                        value4={resultValue4}
                        onClose={handleBackClick}
                    />
                )}
            </div>
        </div>
    );
});
export default LeaderboardMobile;