import React, { useState } from "react";
// import './style.css';

function Form({ onSubmit, onBackClick }) {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit(inputValue);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="form-check">
        <div className="form-container">
        <h4>Unesi svoj ID</h4>
        <form onSubmit={handleSubmit}>
            <label>
            <input type="text" value={inputValue} placeholder="npr. 33160" onChange={handleInputChange} />
            </label>
            <div className="button_wrapper">
                <button className="mat-button green-button" type="submit" disabled={loading}>
                {loading ? "UČITAVAM..." : "PRONAĐI ME"}
                </button>
            </div>
            <br />
            <button className="mat-button button__back" type="button" onClick={onBackClick}>
                NAZAD
            </button>
        </form>
        </div>
    </div>
  );
}

export default Form;
