import React from "react";
// import './style.css';

function Result({ onClose, value1, value2, value3, value4 }) {
    return (
        <div className="result">
            <div className="response">
                {/* <h2>Result</h2> */}
                {value4 === '' && <p>Čestitamo!<br />Osvojio/la si {value1} poena<br />{value3}!<br />Tvoja pozicija je #{value2}</p>}
                {value4 !== '' && <p>{value4}</p>}
            </div>
            <div className="button_wrapper">
                <button className="mat-button button__back" type="button" onClick={onClose}>
                    NAZAD
                </button>
            </div>
        </div>
    );
}

export default Result;