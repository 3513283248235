// import React from "react";
// import ReactDOM from "react-dom";
// import Leaderboard from "./Leaderboard";
// import LeaderboardMobile from "./LeaderboardMobile";

// ReactDOM.render(
//   <LeaderboardMobile />
// , document.getElementById("root"));

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import './index.css';
import Leaderboard from "./Leaderboard";
import LeaderboardMobile from "./LeaderboardMobile";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserView>
        <Leaderboard />
      </BrowserView>
      <MobileView>
        <LeaderboardMobile />
      </MobileView>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
